<template>
  <v-card>
    <v-card-title>問い合わせ-編集</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label>氏名</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="faqForm.faq_sei"
                outlined
                dense
                readonly
              ></v-text-field>
              <!-- <v-text-field
                v-model="$v.faqForm.faq_sei.$model"
                outlined
                dense
                readonly
              ></v-text-field> -->
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="faqForm.faq_mei"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label>氏名(カタカナ)</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="faqForm.faq_sei_kana"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="faqForm.faq_mei_kana"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_tel">電話番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="faqForm.faq_tel"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_email">メールアドレス</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="faqForm.faq_email"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label>問い合わせ日</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="faqForm.faq_date"
                readonly
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_content">問い合わせ内容</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="faqForm.faq_content"
                rows="7"
                name="note"
                outlined
                dense
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_tel">問い合わせ状態</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="$v.faqForm.faq_code.$model"
                :error-messages="codeError"
                :items="statuss"
                placeholder="状態"
                outlined
                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label for="faq_memo">処理メモ</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-textarea
                v-model="$v.faqForm.faq_memo.$model"
                :error-messages="memoError"
                name="note"
                hide-details="auto"
                outlined
                :counter="255"
                dense
                rows="5"
                placeholder="処理メモ"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit(faqForm)"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </template>
  </v-card>
</template>

<script>
import {
  mapState, mapMutations, mapActions,
} from 'vuex'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'

export default {
  data: () => ({
    submitStatus: false,
    changeFlag: false,
    datePickMenu: '',
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    statuss: [
      {
        text: '未処理', value: 0,
      },
      {
        text: '受理', value: 1,
      },
      {
        text: '回答済', value: 2,
      },
      {
        text: '要確認', value: 3,
      },
      {
        text: '要追跡', value: 4,
      },
    ],
    faqForm: {
      faq_sei: '',
      faq_mei: '',
      faq_sei_kana: '',
      faq_mei_kana: '',
      faq_code: '',
      faq_tel: '',
      faq_email: '',
      faq_date: '',
      faq_content: '',
      faq_memo: '',
    },
  }),
  validations: {
    faqForm: {
      faq_code: {
        required,
      },
      faq_memo: {
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    ...mapState('faqStore', ['selectedFaq']),
    codeError() {
      const errors = []

      if (!this.$v.faqForm.faq_code.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_code.required && errors.push('必須項目')

      return errors
    },
    memoError() {
      const errors = []
      if (!this.$v.faqForm.faq_memo.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faqForm.faq_memo.maxLength && errors.push('最大255文字までです！')

      return errors
    },
  },
  watch: {
    faqForm: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('faqStore', ['loadFaq', 'editFaq']),

    loadData() {
      this.setOverlayStatus(true)
      this.loadFaq(this.$route.params.id)
        .then(() => {
          this.faqForm = this.$deepCopy(this.selectedFaq)
        })
        .catch(error => {
          console.log(error.response)
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },

    jumpToListPage() {
      this.$router.push({
        path: '/faq-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    cancelClick() {
      if (!this.$isObjectEqual(this.faqForm, this.selectedFaq)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    submit(faqForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        console.log('submit', faqForm)
        this.submitStatus = true
        this.editFaq(faqForm).then(() => {
          this.jumpToListPage()
        })
          .catch(error => {
            console.log('error', error)
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
      }
    },
  },
}
</script>
